import React from 'react';
import { Link } from 'react-router-dom';

const ProductLink = ({ to, children, ...props }) => {
  const handleClick = (e) => {
    e.preventDefault(); // Prevent the default link behavior
    window.location.href = to; // Navigate to the target URL
  };

  return (
    <Link to={to} onClick={handleClick} {...props}>
      {children}
    </Link>
  );
};

export default ProductLink;