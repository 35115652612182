// src/pages/HomePage.js

import React from 'react';
import Banner from '../components/Banner';
import ProductCard from '../components/ProductCard';
import '../styles/HomePage.css'; // Import the new CSS file

const HomePage = () => {
  return (
    <>
      <Banner
        image="/images/travel-mountains-outdoors.jpg"
        texts={["iSightSeeing", "Explore the beauty of the United States!"]}
        interval={6000}
      />
      <div className="homepage-wrapper">
        <div className="homepage-container">
          {/* Regions Section */}
          <div className="regions-section">
            <h2 className="regions-title">
              Explore Our Experiences Around the U.S.
            </h2>
            <div className="regions-grid">
              <ProductCard
                image="/images/regions/east-coast.jpeg"
                text="East Coast"
                url="/east-coast"
              />
              <ProductCard
                image="/images/regions/south.jpeg"
                text="South"
                url="/south"
              />
              <ProductCard
                image="/images/regions/midwest.jpeg"
                text="Midwest"
                url="/midwest"
              />
              <ProductCard
                image="/images/regions/mountain-west.jpeg"
                text="Mountain West"
                url="/mountain-west"
              />
              <ProductCard
                image="/images/regions/west-coast.jpeg"
                text="West Coast"
                url="/west-coast"
              />
              <ProductCard
                image="/images/regions/pacific-northwest.jpeg"
                text="Pacific Northwest"
                url="/pacific-northwest"
              />
              <ProductCard
                image="/images/regions/hawaii.jpeg"
                text="Hawaii"
                url="/hawaii"
              />
              <ProductCard
                image="/images/regions/alaska.jpeg"
                text="Alaska"
                url="/alaska"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
