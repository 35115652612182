import React, { useState, useEffect } from 'react';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import '../styles/Banner.css';

const Banner = ({ image, texts, interval }) => {
  const textsArray = useMemo(() => Array.isArray(texts) ? texts : [texts], [texts]);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [isFadingOut, setIsFadingOut] = useState(false);

  useEffect(() => {
    if (textsArray.length > 1 && interval) {
      const textChangeInterval = setInterval(() => {
        setIsFadingOut(true);
        setTimeout(() => {
          setCurrentTextIndex((prevIndex) => (prevIndex + 1) % textsArray.length);
          setIsFadingOut(false);
        }, 500); // Fade duration
      }, interval);

      return () => clearInterval(textChangeInterval);
    }
  }, [textsArray, interval]);

  const isLargeText = currentTextIndex === 0; // First text is larger

  return (
    <div className="banner">
      <img src={image} alt="Banner" loading="lazy" className="banner-image" />
      <div className="banner-content">
        <div className="banner-text-container">
          <h1
            className={`banner-heading ${isLargeText ? 'large-heading' : 'small-heading'} ${
              isFadingOut ? 'fade-out' : 'fade-in'
            }`}
          >
            {textsArray[currentTextIndex]}
          </h1>
        </div>
      </div>
    </div>
  );
};

Banner.propTypes = {
  image: PropTypes.string.isRequired,
  texts: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  interval: PropTypes.number,
};

Banner.defaultProps = {
  interval: null,
};

export default Banner;
