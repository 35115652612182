import React from 'react';
import PropTypes from 'prop-types';
import '../styles/ProductCard.css';

const ProductCard = ({ image, text, url }) => {
  return (
    <div
      className="product-card"
      onClick={() => window.location.href = url}
    >
      <img
        src={image}
        alt={text}
      />
      <div className="product-card-overlay">
        <div className="product-card-text-container">
          <span className="product-card-text">{text}</span>
        </div>
      </div>
    </div>
  );
};

ProductCard.propTypes = {
  image: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default ProductCard;
