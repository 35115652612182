// src/components/Navbar.js

import React, { useEffect, useState } from 'react';
import '../styles/Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import productData from '../data/productData';
import ProductLink from './ProductLink';

const Navbar = () => {
  const [isShrunk, setIsShrunk] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openSubmenus, setOpenSubmenus] = useState({});

  // Checks for user scrolling for shrinking the navbar
  const handleScroll = () => {
    setIsShrunk(window.scrollY > 50);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Toggle the mobile menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (isMenuOpen) {
      setOpenSubmenus({}); // Reset submenus when closing
    }
  };

  // Toggle submenus in mobile view
  const toggleSubmenu = (keyPath) => {
    setOpenSubmenus((prevOpenSubmenus) => ({
      ...prevOpenSubmenus,
      [keyPath]: !prevOpenSubmenus[keyPath],
    }));
  };

  // Recursive function to render menu items
  const renderMenuItems = (data, pathSegments = [], isMobile = false) => {
    return (
      <ul className="menu-level">
        {Object.keys(data).map((key) => {
          const item = data[key];
          const url = `/${[...pathSegments, key].join('/')}`;
          const displayName = item.name;
          const keyPath = url; // Use URL as a unique key

          if (item.states) {
            // Region level
            return (
              <li
                key={keyPath}
                className={`menu-item ${isMobile && openSubmenus[keyPath] ? 'open' : ''}`}
              >
                <div
                  className="menu-link"
                  onClick={isMobile ? () => toggleSubmenu(keyPath) : undefined}
                >
                  {displayName}
                  <FontAwesomeIcon
                    icon={
                      isMobile
                        ? openSubmenus[keyPath]
                          ? faChevronUp
                          : faChevronDown
                        : faChevronRight
                    }
                    className="menu-icon"
                  />
                </div>
                {(isMobile ? openSubmenus[keyPath] : true) && (
                  <div className="submenu">
                    {renderMenuItems(item.states, [...pathSegments, key], isMobile)}
                  </div>
                )}
              </li>
            );
          } else if (item.cities || item.attractions) {
            // State level
            const attractions = [];

            // Collect attractions directly under the state
            if (item.attractions) {
              Object.keys(item.attractions).forEach((attractionKey) => {
                attractions.push({
                  key: attractionKey,
                  item: item.attractions[attractionKey],
                  path: [...pathSegments, key, attractionKey],
                });
              });
            }

            // Process cities
            if (item.cities) {
              Object.keys(item.cities).forEach((cityKey) => {
                const cityItem = item.cities[cityKey];
                const cityAttractions = cityItem.attractions || {};
                const attractionKeys = Object.keys(cityAttractions);

                if (attractionKeys.length === 1) {
                  // City has only one attraction; flatten it
                  const attractionKey = attractionKeys[0];
                  attractions.push({
                    key: attractionKey,
                    item: cityAttractions[attractionKey],
                    path: [...pathSegments, key, cityKey, attractionKey],
                  });
                } else {
                  // City has multiple attractions; keep it as a submenu
                  attractions.push({
                    key: cityKey,
                    item: cityItem,
                    path: [...pathSegments, key, cityKey],
                    isCity: true,
                  });
                }
              });
            }

            // Render the state with its attractions and cities
            return (
              <li
                key={keyPath}
                className={`menu-item ${isMobile && openSubmenus[keyPath] ? 'open' : ''}`}
              >
                <div
                  className="menu-link"
                  onClick={isMobile ? () => toggleSubmenu(keyPath) : undefined}
                >
                  {displayName}
                  <FontAwesomeIcon
                    icon={
                      isMobile
                        ? openSubmenus[keyPath]
                          ? faChevronUp
                          : faChevronDown
                        : faChevronRight
                    }
                    className="menu-icon"
                  />
                </div>
                {(isMobile ? openSubmenus[keyPath] : true) && (
                  <div className="submenu">
                    <ul className="menu-level">
                      {attractions.map(({ key, item, path, isCity }) => {
                        const itemUrl = `/${path.join('/')}`;
                        const itemKey = itemUrl;
                        if (isCity) {
                          // City with multiple attractions
                          return (
                            <li
                              key={itemKey}
                              className={`menu-item ${isMobile && openSubmenus[itemKey] ? 'open' : ''}`}
                            >
                              <div
                                className="menu-link"
                                onClick={isMobile ? () => toggleSubmenu(itemKey) : undefined}
                              >
                                {item.name}
                                <FontAwesomeIcon
                                  icon={
                                    isMobile
                                      ? openSubmenus[itemKey]
                                        ? faChevronUp
                                        : faChevronDown
                                      : faChevronRight
                                  }
                                  className="menu-icon"
                                />
                              </div>
                              {(isMobile ? openSubmenus[itemKey] : true) && (
                                <div className="submenu">
                                  {renderMenuItems(item.attractions, path, isMobile)}
                                </div>
                              )}
                            </li>
                          );
                        } else {
                          // Attraction
                          return (
                            <li key={itemKey} className="menu-item">
                              <a
                                href={itemUrl}
                                className="menu-link"
                                onClick={() => isMobile && setIsMenuOpen(false)} // Close menu on selection in mobile
                              >
                                {item.name}
                              </a>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                )}
              </li>
            );
          } else {
            // Attraction (leaf node)
            return (
              <li key={keyPath} className="menu-item">
                <ProductLink
                  to={url}
                  className="menu-link"
                  onClick={() => isMobile && setIsMenuOpen(false)} // Close menu on selection in mobile
                >
                  {displayName}
                </ProductLink>
              </li>
            );
          }
        })}
      </ul>
    );
  };

  return (
    <>
      <nav className={`navbar ${isShrunk ? 'shrunk' : ''}`}>
        <div className="navbar-container">
          {/* Logo and Home Link */}
          <div className="navbar-left">
            <a href="/" className="logo-link">
              <div className="logo-container">
                <img
                  src="/images/isightseeing.png"
                  alt="Logo"
                  className="logo-image"
                />
              </div>
            </a>
            <a href="/" className="nav-link desktop-only">
              Home
            </a>

            {/* Dropdown Menu for Desktop */}
            <div className="navbar-item desktop-only">
              <div className="nav-link cursor-pointer">
                Destinations
                <FontAwesomeIcon icon={faChevronDown} className="nav-icon" />
              </div>
              <div className="navbar-dropdown">
                {renderMenuItems(productData.regions)}
              </div>
            </div>
          </div>

          {/* Hamburger Menu Icon for Mobile */}
          <div className="mobile-only">
            <div
              className={`hamburger-icon ${isMenuOpen ? 'open' : ''}`}
              onClick={toggleMenu}
            >
              <div className="icon-bar"></div>
              <div className="icon-bar"></div>
              <div className="icon-bar"></div>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className={`mobile-menu ${isMenuOpen ? 'slide-in' : 'slide-out'}`}>
          <div className="mobile-menu-content">
            <a
              href="/"
              className="menu-link"
              onClick={() => setIsMenuOpen(false)}
            >
              Home
            </a>
            {/* Directly render all destinations without needing to click "Destinations" */}
            {renderMenuItems(productData.regions, [], true)}
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
