import React from 'react';
import '../styles/Footer.css'

const Footer = () => {
    return (
        <footer className="text-white py-6">
            <div className="container mx-auto flex justify-between items-center px-6">
                <div className="flex items-center">
                    <img src="/images/attractionlogo.png" alt="A4U Logo" className="h-12 mr-4" />
                </div>
                <div>
                    <p>Contact us</p>
                    <p>reservations@isightseeing.com</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;